import React from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Link } from '../../content';
import { BaseProps } from '../../types';
import { BackLink } from './BackButton.style';
import { useTestIds } from './BackButton.model';

type BackButtonProps = {};

export const BackButton: React.FC<BackButtonProps & BaseProps> = ({
  children = 'Back',
  ...props
}) => {
  const testIds = useTestIds(props);
  const history = useHistory();

  const onClick = () => {
    history.goBack();
  };

  return (
    <BackLink onClick={onClick} {...testIds.base.attr}>
      <KeyboardArrowLeftIcon {...testIds.arrow.attr} />
      <Link {...testIds.label.attr}>{children}</Link>
    </BackLink>
  );
};
