import React from 'react';

import { Bar, BarContainer, Count, StepCounterContainer } from './StepCounter.style';
import { StepCounterProps } from './StepCounter.model';

export const StepCounter = ({ currentStep, maxStep }: StepCounterProps) => {
  return (
    <StepCounterContainer>
      <Count>
        {currentStep} / {maxStep}
      </Count>
      <BarContainer>
        {[...Array(maxStep)].map((_e, index) => {
          return <Bar key={index} complete={index + 1 === currentStep} />;
        })}
      </BarContainer>
    </StepCounterContainer>
  );
};
